import { yup } from './validate';
const { object, array, string, number, boolean, date } = yup;

const loginSchema = object({
  login: string().min(5).max(200).required(),
  password: string().min(5).max(30).required(),
});
const loginSendCodeSchema = object({
  login: string().min(5).max(30).required(),
});

const itemBlockSchema = object({
  id: number().moreThan(0).nullable(true),
  name: string().max(150).required(),
  desc: string().max(500).nullable(true),
  productTypeId: number().moreThan(0).required(),
  categoryId: number().moreThan(0).required(),
  growTypeId: number().moreThan(0).required(),
  deleted: boolean().nullable(true),
  article: object({
    id: number().moreThan(0).required(),
    desc: string().max(500).nullable(true),
  }),
  itemSexes: array()
    .min(1)
    .of(
      object({
        sexId: number().moreThan(0).required(),
        deleted: boolean().nullable(true),
        sizeChart: array().min(1),
      })
    ),
  itemFabricPrices: array()
    .min(1)
    .of(
      object({
        fabricId: number().moreThan(0).required(),
        deleted: boolean().nullable(true),
        prices: object({
          RUR: number().moreThan(0).required(),
          EUR: number().moreThan(0).nullable(true),
        }),
      }).required()
    ),
}).required();

const setBlockSchema = object({
  id: number().moreThan(0).nullable(true),
  name: string().max(150).required(),
  prices: object({
    RUR: number().moreThan(0).required(),
    EUR: number().moreThan(0).nullable(true),
  }),
  itemSets: array()
    .min(2)
    .of(
      object({
        id: number().moreThan(0).nullable(true),
        index: number().moreThan(-1).nullable(true),
        itemId: number().moreThan(0).required(),
        fabricId: number().moreThan(0).required(),
        deleted: boolean().nullable(true),
      })
    )
    .required(),
}).required();

const baseEntityAssortmentSchema = object({
  id: number().moreThan(0).nullable(true),
  name: string().max(150).required(),
  deleted: boolean().nullable(true),
}).required();

const productTypeAssortmentSchema = object({
  id: number().moreThan(0).nullable(true),
  name: string().max(150).required(),
  complexity: string().max(15).required(),
  deleted: boolean().nullable(true),
}).required();

const articleAssortmentSchema = object({
  id: number().moreThan(0).nullable(true),
  name: string().max(150).required(),
  code: string().max(50).required(),
  desc: string().max(500).required(),
  techComment: string().max(500).nullable(true),
  file: string().max(500).nullable(true),
  mockup: object({
    front: string().max(500).nullable(true),
    back: string().max(500).nullable(true),
    plain: string().max(500).nullable(true),
  }),
  deleted: boolean().nullable(true),
}).required();

const baseUserSchema = {
  guid: string().nullable(true),
  name: string().max(50).nullable(true),
  patronymic: string().max(50).nullable(true),
  surname: string().max(50).nullable(true),
  currency: string().required(),
  contacts: object({
    phone: string()
      .min(18)
      .nullable(true)
      .when('email', {
        is: (email) => !email,
        then: string().required(),
      }),
    email: string().max(200).nullable(true),
  }),
  type: string().required(),
  deleted: boolean().nullable(true),
};

const userBlockSchema = object({
  ...baseUserSchema,
}).required();

const employeeBlockSchema = object({
  ...baseUserSchema,
  name: string().max(50).required(),
  contacts: object({
    phone: string().min(18).nullable(true).required(),
    email: string().max(200).nullable(true),
  }),
  departmentIds: array().min(1).of(number()).required(),
}).required();

const orderSchema = object({
  guid: string().length(36).nullable(true),
  name: string().max(200).nullable(true),
  clientId: string().required(),
  currency: string().required(),
  createdAt: date().nullable(true),
  deadline: date().nullable(true),
  postponedDeadline: date().nullable(true),
  urgencyType: string().nullable(true),
  tagId: number().nullable(true),
  discountFix: number().nullable(true),
  discountPercent: number().min(-100).max(100).nullable(true),

  paid: number().min(0).nullable(true),
  teamId: string().length(36).nullable(true),
  teamName: string().max(30).nullable(true),

  players: array()
    .min(0)
    .of(
      object({
        fio: string().nullable(true),
        // guid: string().length(36).nullable(true),
        // orderId: string().length(36).nullable(true),
        index: number().min(0).required(),
        // lettering: string().max(30).nullable(true),
        // number: string().max(30).nullable(true),
        sexId: number().max(5).required(),
        comment: string().max(40).nullable(true),
      })
    ),
  products: array()
    .min(0)
    .of(
      object({
        itemId: number().required(),
        fabricId: number().required(),
        defPrice: number().nullable(true),
        discountPercent: number().min(-100).nullable(true),
        // filePath: string().max(150).nullable(true),
        // kitId: string().length(36).nullable(true),

        // isFree: boolean().nullable(true),

        // guid: string().length(36).nullable(true),
        // orderId: string().length(36).nullable(true),
        // clientId: string().length(36).nullable(true),
        // managerId: string().length(36).nullable(true),
        index: number().min(0).required(),
        localIndex: number().min(0).nullable(),

        color: string().max(30).nullable(true),
        comment: string().max(40).nullable(true),
      })
    ),
  playerProducts: array()
    .min(0)
    .of(
      array()
        .min(0)
        .of(
          object({
            // id: number().nullable(true),
            comment: string().max(40).nullable(true),
            quantity: number().min(0).nullable(true),
            size: string()
              .required()
              .when('quantity', (quantity) => {
                if (!quantity) {
                  return yup.string().nullable(true);
                }
              }),
          })
        )
    ),
  kits: array()
    .min(0)
    .of(
      object({
        guid: string().length(36).required(),
        clientId: string().nullable(true),
        defPrice: number().required().min(0),
        deleted: boolean().nullable(true),
        discountFix: number().nullable(true),
        discountPercent: number().min(-100).nullable(true),
        index: number().required(),
        isFree: boolean().nullable(true),
        orderId: string().nullable(true),
        setId: string().required(),
      })
    ),
}).required();

const departmentBlockSchema = object({
  id: number().moreThan(0).nullable(true),
  name: string().min(2).max(20).required(),
  tableIndex: number().positive().integer().moreThan(0).max(1000).required(),
  shiftDuration: number().positive().integer().moreThan(0).nullable(true),
  isActive: boolean().nullable(true),
  deleted: boolean().nullable(true),
  changeAllCards: boolean().required(),
  multipleCardsInWork: boolean().required(),
  subDepartments: array()
    .min(0)
    .of(
      object({
        id: number().moreThan(0).nullable(true),
        name: string().min(2).max(20).required(),
        employeeIds: array().min(0).of(string().length(36)),
        index: number().positive().integer().moreThan(0).max(1000).required(),
        departmentId: number().positive().integer().moreThan(0).nullable(true),
        isDefault: boolean().nullable(true),
      })
    ),
}).required();

const orderTagBlockSchema = object({
  id: number().moreThan(0).nullable(true),
  name: string().min(2).max(20).required(),
  deleted: boolean().nullable(true),
}).required();

const orderCategoryBlockSchema = object({
  id: number().moreThan(0).nullable(true),
  name: string().min(2).max(20).required(),
  deleted: boolean().nullable(true),
}).required();

const baseProductionLineBlockSchema = {
  id: number().moreThan(0).nullable(true),
  name: string().min(2).max(50).required(),
  departmentSettings: array()
    .min(1)
    .of(
      array()
        .min(1)
        .of(
          object({
            id: number().moreThan(0).nullable(true),
            productionLineId: number().moreThan(0).nullable(true),
            departmentId: number().moreThan(0).required(),
            itemId: number().moreThan(0).nullable(true),
            index: number().moreThan(-1).nullable(true),
            commissioning: number().moreThan(0).integer().nullable(true),
            employeeIncomePerUnit: number().moreThan(0).integer().nullable(true),
            timePerUnit: number().moreThan(0).integer().nullable(true),
            required: boolean().nullable(true),
          })
        )
    ),
};
const productionLineBlockSchema = object(baseProductionLineBlockSchema).required();

const itemProductionLineBlockSchema = object({
  ...baseProductionLineBlockSchema,
  itemId: number().moreThan(0).required(true),
}).required();

export {
  loginSchema,
  loginSendCodeSchema,
  orderSchema,
  itemBlockSchema,
  setBlockSchema,
  baseEntityAssortmentSchema,
  productTypeAssortmentSchema,
  articleAssortmentSchema,
  userBlockSchema,
  employeeBlockSchema,
  departmentBlockSchema,
  orderTagBlockSchema,
  orderCategoryBlockSchema,
  productionLineBlockSchema,
  itemProductionLineBlockSchema,
};
